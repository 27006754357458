import React from "react"

const Box = ({padding, onClick, children}) => {
  console.log(children)
  return (
    <div style={{textAlign: "center", color: "white", width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center'}}>
      <div onClick={onClick} style={{borderStyle: "ridge", borderWidth: "20px", borderColor: "brown", background: "black"}}>
      <div style={{paddingLeft: padding, paddingRight: padding}}>
        {children}
      </div>
    </div>

  </div>
  )
}

export default Box