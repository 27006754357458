import React from 'react';
import mountain from '../../imgs/mountain.png'

const Level4 = ({progress}) =>  {
  return (
    <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "100px"}}/>
        <img onClick={progress} style={{width: "350px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={mountain} alt={"mountain"}/>
      <h3>
        Click on this image...
      </h3>
    </div>
  );
}

export default Level4;