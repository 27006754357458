import React, {useEffect, useRef, useState} from 'react';
import attack_url from '../../imgs/attack.png'
import block_url from '../../imgs/block.png'
import rest_url from '../../imgs/static.png'
import flamed_url from '../../imgs/flamed.png'
import red_eyes_url from '../../imgs/red_eyes.png'
import heart from '../../gifs/heart.gif'

const Level23 = ({progress, failure, playSFX}) => {

  const [src, setSrc] = useState(rest_url)
  const [level, setLevel] = useState(1)
  const difficulty= useRef(700)
  const curTimeoutRef = useRef(0)
  const reflexTime = useRef(0)
  const dragonCharge = useRef(0)
  const numBlocks = useRef(0)
  const reflexSet = useRef(false)

  useEffect(() => {
    if (level === 2) {
      difficulty.current = 1000
    } else if (level === 3) {
      difficulty.current = 1000
    }
    if (level > 3) {
      progress()
    }
  }, [level, progress])

  useEffect(() => {


    const kennaAttack = () => {
      console.log("kenna attack")
      playSFX('hit')
      numBlocks.current = 0;
      setSrc(attack_url)
      setLevel(prevLevel => {
        const newLevel = prevLevel + 1
        return newLevel
      })
      curTimeoutRef.current = setTimeout(rest, 1000)
    }
  
    const rest = () => {
      console.log("dragon rest")
      reflexSet.current = false
      setSrc(rest_url)
      curTimeoutRef.current = setTimeout(dragonPrep, (Math.random() * 5000) + 1000)
    }
  
    const dragonAttack = () => {
      console.log("dragon attack")
      
      console.log("diff: " + (reflexTime.current - dragonCharge.current))
      console.log(reflexTime.current)
      playSFX('flame')    
      const diff = (reflexTime.current - dragonCharge.current)
  
      if (diff >= 0 && diff < difficulty.current) {
        setSrc(block_url)
        numBlocks.current = numBlocks.current + 1
        if (numBlocks.current >= 3) {
          curTimeoutRef.current = setTimeout(kennaAttack, 1000)
        } else {
          curTimeoutRef.current = setTimeout(rest, 1000)
        }
      } else {
        setSrc(flamed_url)
        curTimeoutRef.current = setTimeout(failure, 1000)
      }
    }
  
    const dragonPrep = () => {
      console.log("dragon prep")
      playSFX('wind_up')
      setSrc(red_eyes_url)
      dragonCharge.current = Date.now()
      console.log(dragonCharge.current)
      console.log()
      curTimeoutRef.current = setTimeout(dragonAttack, difficulty.current)
    }

    const setReflex = () => {
      console.log(reflexSet.current)
      if (!reflexSet.current) {
        reflexTime.current = Date.now();
        reflexSet.current = true
      }
    }

    document.addEventListener('click', setReflex)
    document.addEventListener('keydown', setReflex)
    curTimeoutRef.current = setTimeout(dragonPrep, 5000);
    return (() => {
      console.log("clearing game")
      clearTimeout(curTimeoutRef.current)
      document.removeEventListener('click', setReflex)
      document.removeEventListener('keydown', setReflex)
    })
  }, [failure, playSFX, progress])

  return (
      <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "100px"}}/>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        <div style={{position: "relative"}}>
          <img style={{width: "400px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={src} alt={"dragon"}/>

          {(level < 2) && <img src={heart} alt={"heart"} style={{position: 'absolute', width: 80, top: 30, left: 20}}/>}
          {(level < 3) && <img src={heart} alt={"heart"} style={{position: 'absolute', width: 80, top: 30, left: 60}}/>}
          {(level < 4) && <img src={heart} alt={"heart"} style={{position: 'absolute', width: 80, top: 30, left: 100}}/>}
        </div>
      </div>
      <h3>
        Click when the time is right!
      </h3>
    </div>
  );
};

export default Level23;