import you_lose from "../../imgs/you_lose.png"
import torch from "../../gifs/torch.gif"
import hint from "../../imgs/hint.png"

const Level0 = ({progress}) => {
  return (
    <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "100px"}}/>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        <img src={torch} alt={"torch"}/>
        <div onClick={progress} style={{position: "relative"}}>
          <img style={{width: "350px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={you_lose} alt={"you_lose"}/>
        </div>
        <img src={torch} alt={"torch"}/>
      </div>
      <h3>
        Click the dragon to try again!
      </h3>
      <a href="https://tinyurl.com/mr2jm585" target="_blank" rel="noreferrer">
        <img  style={{position: "fixed", width: 180, bottom: 0, right: 0}} src={hint} alt={"hint"}/>
      </a>
    </div>
  )
}

export default Level0