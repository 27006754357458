import React from "react"
import Box from "./Box"

const SoundPrompt = ({initSound}) => {
  return (
    <Box onClick={initSound} padding={10}>
        <h3 style={{paddingLeft: "10px", paddingRight: "10px"}}>
          Are you ok with this website playing sound?
        </h3>
        <h3>
          Click this box if you're cool with that.
        </h3>
    </Box>
  )
}

export default SoundPrompt