import React from 'react';
import Box from './Box';
import btn from '../imgs/button.png';

function MultiButtonClick({progress, failure, correctButtonIndex, prompt, numButtons, }) {

  let buttons = []
  
  for (let i = 0; i < numButtons; i++) {
    buttons = [...buttons, <img key={i} style={{width: 50, paddingLeft: 10, paddingRight: 10}} onClick={i === correctButtonIndex ? progress : failure} src={btn} alt={"button"}/>]
  }


  return (
    <Box progress={progress} padding={10}>
      <h3>
        {prompt}
      </h3>
      <div>
        {buttons}
      </div>
    </Box>
  );
};

export default MultiButtonClick;