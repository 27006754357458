import React from "react"
import Box from "./Box"

const MobileWarning = ({initSound}) => {
  return (
    <Box onClick={()=> {}} padding={10}>
        <h3 style={{paddingLeft: "10px", paddingRight: "10px"}}>
          Hey! This is a desktop experience! Get off your phone! Please!
        </h3>
    </Box>
  )
}

export default MobileWarning