import React from 'react';
import family from '../../imgs/family.png'

const Level19 = ({progress, failure}) => {
  return (
    <div style={{textAlign: "center", color: "white"}}>
    <div style={{height: "100px"}}/>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
      <div style={{position: "relative"}}>
        <img style={{width: "400px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={family} alt={"family"}/>
        <div onClick={failure} style={{position: "absolute", width: 270, height: 220, right: 145, top: 200}}/>
        <div onClick={failure} style={{position: "absolute", width: '100%', height: '45%', right: 0, top: 0 }}/>
        <div onClick={progress} style={{position: "absolute", width: 120, height: 210, right: 20, top: 200}}/>
      </div>
    </div>
    <h3>
      Choose the RIGHT family member
    </h3>
  </div>
  );
};

export default Level19;