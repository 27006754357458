import kennaquest from "../../imgs/kennaquest.png"
import torch from "../../gifs/torch.gif"

const Level1 = ({progress}) => {
  return (
    <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "100px"}}/>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        <img src={torch} alt={"torch"}/>
        <div style={{position: "relative"}}>
          <img style={{width: "350px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={kennaquest} alt={"kennaquest"}/>
          <div onClick={progress} style={{position: "absolute", width: 50, height: 50, right: 20, top: 230}}>
          </div>
        </div>
        <img src={torch} alt={"torch"}/>
      </div>
      <h3>
        To begin your adventure, find the passage and enter...
      </h3>
    </div>
  )
}

export default Level1