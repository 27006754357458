import React, {useState} from 'react';
import Box from './Box';

function Riddle({correctAnswer, prompt, progress, failure}) {
  const [answer, setAnswer] = useState("")


  const handleChange = (e) => {
    setAnswer(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (answer.toLowerCase() === correctAnswer) {
      progress()
    } else {
      failure()
    }
  }

  return (
    <Box padding={10}>
      <h3> Solve this riddle... </h3>
      <h4> {prompt} </h4>
      <form onSubmit={handleSubmit}>
        <label>
        <input type={"text"} value={answer} onChange={handleChange}/>
        </label>
        <input type={"submit"} value={"Submit"}/>
      </form>
      <div style={{height: 10}}/>
    </Box>
  );
}

export default Riddle;