import React from 'react';
import wizard from '../../imgs/wizard_dwight.png'

const Level24 = () => {
  return (
    <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "50px"}}/>
      <a href='https://dundermifflinpaper.com/products/the-adventures-of-jimmy-halpert' rel="noreferrer" target="_blank">
        <img style={{width: "350px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={wizard} alt={"wizard_dwight"}/>
      </a>
      <h3>
        Click wizard dwight to see what's coming in the mail.
      </h3>
    </div>
  );
};

export default Level24;