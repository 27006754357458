import React from 'react';
import ButtonClick from '../ButtonClick';

const Level8 = ({progress}) => {
  return (
    <ButtonClick prompt={"click the button again"} progress={progress}/>

  );
};

export default Level8;