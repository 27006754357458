import React, {useEffect, useState} from 'react';
import ButtonClick from '../ButtonClick';

const Level11 = ({progress, playSuccessSound}) => {
  const [clickCounter, setClickCounter] = useState(0)

  useEffect(() => {
    const goal = 20;
    if (clickCounter >= goal) {
      progress()
    }
  }, [clickCounter, progress])

  const countClick = () => {
    playSuccessSound()
    setClickCounter(prevCount => prevCount + 1)
  }

  return (
    <ButtonClick prompt={"Click it a lot"} progress={countClick}/>
  );
};

export default Level11;