import React  from 'react';
import champion_selection from '../../imgs/champion_selection.png'

const Level22 = ({progress, setCharacter, playSFX}) => {

  const selectHev = () => {
    playSFX("hev")
    setCharacter("hev")
    progress()
  }

  const selectMike = () => {
    playSFX("mike")
    setCharacter("mike")
    progress()
  }

  return (
      <div style={{textAlign: "center", color: "white"}}>
      <div style={{height: "100px"}}/>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        <div style={{position: "relative"}}>
          <img style={{width: "400px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={champion_selection} alt={"family"}/>
          <div onClick={selectHev} style={{position: "absolute", width: 170, height: 260, left: 20, top: 23}}/>
          <div onClick={selectMike} style={{position: "absolute", width: '45%', height: '90%', right: '5%', top: '5%'}}/>
        </div>
      </div>
      <h3>
        Select your champion
      </h3>
    </div>
  );
};

export default Level22;