import React, {useEffect, useRef, useState} from 'react';
import arm_0 from '../../imgs/arm_1.png'
import arm_1 from '../../imgs/arm_2.png'
import arm_2 from '../../imgs/arm_3.png'
import arm_3 from '../../imgs/arm_4.png'
import arm_4 from '../../imgs/arm_5.png'


const Level20 = ({progress, failure, character}) => {

  console.log(character)
  const [armState, setArmState] = useState(2);
  const [points, setPoints] = useState(0);

  const pointsRef = useRef(points);
  const armStateRef = useRef(armState);

  useEffect(() => {
    pointsRef.current = points
  }, [points])

  useEffect(() => {
    armStateRef.current = armState
  }, [armState])

  let armUrls = [arm_0, arm_1, arm_2, arm_3, arm_4];
  let difficulty = 7

  if (character === "mike") {
    armUrls = [arm_4, arm_3, arm_2, arm_1, arm_0];
    difficulty = 4
  }
  useEffect(() => {

    console.log("building game")
    const wrestle = () => {
      setPoints(prevPoints => {
        console.log("points: " + (prevPoints + 1))
        return prevPoints + 1
      })

    }

    const tallyPoints = () => {
      console.log("tallying points")
      if (pointsRef.current < difficulty) {
        if (armStateRef.current <= 0) {
          failure()
        } else {
          setArmState(prevArmState => prevArmState - 1)
        }
      } else {
        if (armStateRef.current >= (armUrls.length - 1)) {
          progress()
        } else {
          setArmState(prevArmState => prevArmState + 1)
        }
      }
      setPoints(0)
    }

    const id = setInterval(tallyPoints, 710)
    document.addEventListener('click', wrestle)
    document.addEventListener('keyup', wrestle)

    return (() => {
      clearInterval(id)
      document.removeEventListener('click', wrestle)
      document.removeEventListener('keyup', wrestle)
    })
  }, [setPoints, armUrls.length, difficulty, failure, progress])

  return (
    <div style={{textAlign: "center", color: "white"}}>
    <div style={{height: "100px"}}/>
    <img style={{width: "400px", borderStyle: "ridge", borderColor: "brown", borderWidth: "20px"}} src={armUrls[armState]} alt={"family"}/>
    <h3>
      Press spacebar rapidly to win!!
    </h3>
  </div>
  );
};

export default Level20;