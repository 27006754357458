import React from 'react';
import Riddle from '../Riddle';
import triangle from "../../imgs/triangle.png";

const Level3 = ({progress, failure}) => {
  return (
    <>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 50, top: 25, left: 50}}/>
      <Riddle prompt={"What's James' favorite shape?"} correctAnswer={"triangle"} progress={progress} failure={failure}/>
 
    </>
  );
};

export default Level3;