import React from 'react';
import Box from './Box';
import btn from '../imgs/button.png';

function ButtonClick({progress, prompt}) {
  return (
    <Box progress={progress} padding={10}>
      <h3>
        {prompt}
      </h3>
      <img style={{width: 100}} onClick={progress} src={btn} alt={"button"}/>
    </Box>
  );
};

export default ButtonClick;