import React, {useState, useEffect, useRef} from 'react';
import useWindowSize from '../hooks/useWindowSize'
import SoundPrompt from './SoundPrompt';
import MobileWarning from './MobileWarning';
import Level0 from "./Levels/Level0";
import Level1 from "./Levels/Level1";
import Level2 from './Levels/Level2';
import Level3 from './Levels/Level3';
import Level4 from './Levels/Level4';
import Level5 from './Levels/Level5';
import Level6 from './Levels/Level6';
import Level7 from './Levels/Level7';
import Level8 from './Levels/Level8';
import Level9 from './Levels/Level9';
import Level10 from './Levels/Level10';
import Level11 from './Levels/Level11';
import Level12 from './Levels/Level12';
import Level13 from './Levels/Level13';
import Level14 from './Levels/Level14';
import Level15 from './Levels/Level15';
import Level16 from './Levels/Level16';
import Level17 from './Levels/Level17';
import Level18 from './Levels/Level18';
import Level19 from './Levels/Level19';
import Level20 from './Levels/Level20';
import Level21 from './Levels/Level21';
import Level22 from './Levels/Level22';
import Level23 from './Levels/Level23';
import Level24 from './Levels/Level24';

import main_theme_url from '../sounds/main_theme.mp3'
import failure_theme_url from '../sounds/failure_theme.mp3'
import success_theme_url from '../sounds/victory.mp3'
import click_url from '../sounds/click.wav'
import success_url from '../sounds/success.wav'
import failure_url from '../sounds/failure.wav'
import my_girl_url from '../sounds/mygirl.mp3'
import boss_url from '../sounds/boss.mp3'
import flame_url from '../sounds/flame.wav'
import hit_url from '../sounds/hit.wav'
import wind_up_url from '../sounds/wind_up.wav'
import hev_url from "../sounds/hev.mp3"
import mike_url from "../sounds/mike.mp3"
import fight_url from "../sounds/fight.mp3"


import * as Tone from "tone"

function Game(props) {

  const [levelIndex, setLevelIndex] = useState(0);
  const [character, setCharacter] = useState('hev')
  const [isMobile, setIsMobile] = useState(false)
  const [width] = useWindowSize();
  const sounds = useRef();

  const progress = () => {
    playSuccessSound()
    setLevelIndex(prevIndex => (prevIndex + 1))
  }

  const failure = () => {
    setLevelIndex(1)
  }

  useEffect(() => {
    const mobileSize = 700
    if (width < mobileSize) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [width])

  useEffect(() => {
    if (sounds && sounds.current) {
      ['main_theme', 'failure_theme', 'success_theme', 'my_girl', 'boss', 'fight'].forEach(key => {
        const value = sounds.current[key]
        if (value.loaded && (value.state === "started")) {
          value.stop();
        }
      })
    }    
    if (levelIndex === 1) {
      sounds.current['failure_theme'].start()
    }
    if (levelIndex === 2) {
      sounds.current['main_theme'].start()
    }
    if (levelIndex === 18) {
      sounds.current['my_girl'].start()
    }
    if (levelIndex === 22) {
      sounds.current['fight'].start()
    }
    if (levelIndex === 24) {
      sounds.current['boss'].start()
    }
    if (levelIndex === 25) {
      sounds.current['success_theme'].start()
    }
  }, [levelIndex])

  const initSound = async () => {
    await Tone.start();
    const mainThemeBuffer = new Tone.Player(main_theme_url).toDestination();
    const failureThemeBuffer = new Tone.Player(failure_theme_url).toDestination();
    const successThemeBuffer = new Tone.Player(success_theme_url).toDestination();
    const clickBuffer = new Tone.Player(click_url).toDestination();
    const successBuffer =  new Tone.Player(success_url).toDestination();
    const failureBuffer = new Tone.Player(failure_url).toDestination();
    const myGirlBuffer = new Tone.Player(my_girl_url).toDestination();
    const bossBuffer = new Tone.Player(boss_url).toDestination();
    const flameBuffer = new Tone.Player(flame_url).toDestination();
    const hitBuffer = new Tone.Player(hit_url).toDestination();
    const windUpBuffer = new Tone.Player(wind_up_url).toDestination();
    const hevBuffer = new Tone.Player(hev_url).toDestination();
    const mikeBuffer = new Tone.Player(mike_url).toDestination();
    const fightBuffer = new Tone.Player(fight_url).toDestination();

    Tone.loaded().then(() => {
      mainThemeBuffer.volume.value = -6;
      failureThemeBuffer.volume.value = -6;
      successThemeBuffer.volume.value = -6;
      sounds.current = {
        main_theme: mainThemeBuffer,
        failure_theme: failureThemeBuffer,
        success_theme: successThemeBuffer,
        click: clickBuffer,
        success: successBuffer,
        failure: failureBuffer,
        my_girl: myGirlBuffer,
        boss: bossBuffer,
        flame: flameBuffer,
        hit: hitBuffer,
        wind_up: windUpBuffer,
        hev: hevBuffer,
        mike: mikeBuffer,
        fight: fightBuffer
      }
      setLevelIndex(2)
    })
  }

  const playSuccessSound = () => {
    playSFX('success')
  }

  const playSFX = (name) => {
    if (sounds && sounds.current) {
      if (sounds.current[name].state === "started") {
        sounds.current[name].stop()
      }
      sounds.current[name].start()
    }
  }

  const levels = [
    <SoundPrompt initSound={initSound}/>,
    <Level0 progress={progress}/>,
    <Level1 progress={progress}/>,
    <Level2 progress={progress} failure={failure}/>,
    <Level3 progress={progress} failure={failure}/>,
    <Level4 progress={progress}/>,
    <Level5 progress={progress} failure={failure}/>,
    <Level6 progress={progress} failure={failure}/>,
    <Level7 progress={progress} failure={failure}/>,
    <Level8 progress={progress} failure={failure}/>,
    <Level9 progress={progress} failure={failure}/>,
    <Level10 progress={progress} failure={failure}/>,
    <Level11 progress={progress} playSuccessSound={playSuccessSound}/>,
    <Level12 progress={progress} failure={failure}/>,
    <Level13 progress={progress} failure={failure}/>,
    <Level14 progress={progress} failure={failure}/>,
    <Level15 progress={progress} failure={failure}/>,
    <Level16 progress={progress} failure={failure}/>,
    <Level17 progress={progress} failure={failure}/>,
    <Level18 progress={progress} failure={failure}/>,
    <Level19 progress={progress} failure={failure}/>,
    <Level22 progress={progress} setCharacter={setCharacter} playSFX={playSFX}/>,
    <Level20 progress={progress} failure={failure} character={character}/>,
    <Level21 progress={progress} failure={failure}/>,
    <Level23 progress={progress} failure={failure} playSFX={playSFX}/>,
    <Level24/>
  ]

  if (isMobile) {
    return <MobileWarning/>
  } else {
    return (levels[levelIndex])
  }
}

export default Game;