import React, {useEffect} from 'react';
import ButtonClick from '../ButtonClick';

const Level12 = ({progress, failure}) => {

  useEffect(() => {
    const id = setTimeout(progress, 1000 * 5);
    return () => clearTimeout(id);
  }, [progress])
  return (
    <ButtonClick prompt={"Don't click the button"} progress={failure}/>
  );
};

export default Level12;