import React from 'react';
import ButtonClick from '../ButtonClick';
import triangle from "../../imgs/triangle.png";

const Level7 = ({progress}) => {
  return (
    <>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 20, top: 25, left: 50}}/>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 20, bottom: 25, right: 50}}/>
        <ButtonClick prompt={"click the button"} progress={progress}/>
    </>
  );
};

export default Level7;