import React from 'react';
import triangle from "../../imgs/triangle.png"
import Riddle from '../Riddle';

const Level18 = ({progress, failure}) => {
  return (
    <>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 50, top: 25, left: 50}}/>
      <Riddle prompt={"How many triangles have you seen?"} correctAnswer={"7"} progress={progress} failure={failure}/>
    </>
  );
};

export default Level18;