import React from 'react';
import MultiButtonClick from '../MultiButtonClick';
import triangle from "../../imgs/triangle.png";

const Level14 = ({progress, failure}) => {
  
  return (
    <>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 20, top: 25, left: 50}}/>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 20, bottom: 25, right: 50}}/>
      <MultiButtonClick numButtons={5} correctButtonIndex={4} prompt={"click the right button"} progress={progress} failure={failure}/>
    </>
  );
};

export default Level14;