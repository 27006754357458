import React from 'react';
import Riddle from '../Riddle';

const Level17 = ({progress, failure}) => {
  return (
    <>
      <Riddle prompt={"Name that tune?"} correctAnswer={"my girl"} progress={progress} failure={failure}/>
    </>
  );
};

export default Level17;