import React from 'react';
import Riddle from '../Riddle';

import triangle from "../../imgs/triangle.png";

const Level5 = ({progress, failure}) => {
  return (
    <>
      <img src={triangle} alt={"triangle"} style={{position: "fixed", width: 50, top: 25, right: 50}}/>
        <Riddle prompt={"How many mountains were in the last image?"} correctAnswer={"2"} progress={progress} failure={failure}/>
    </>
  );
};

export default Level5;